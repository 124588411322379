import styles from "../css/program.module.css";
import Page1 from "./programs/reception/Page1";


const ReceptionProgram = () => {
  return (
    <div className={styles.program}>
        <Page1/>
    </div>
  )
}

export default ReceptionProgram