import styles from "../css/program.module.css";
import Page1 from "./programs/photoshoot/Page1";


const Photoshoot = () => {
  return (
    <div className={styles.program}>
        <Page1/>
    </div>
  )
}

export default Photoshoot